import { emptyClient } from '../../constants/initialStates'
import { ReduxAction } from '../../types/common'
import {
  ADD_CLIENT,
  GET_CLIENT,
  GET_CLIENT_ACCOUNT_TOTAL,
  GET_CLIENT_ROLES,
  GET_CLIENTS,
  UPDATE_CLIENT,
  GET_CLIENTS_BEFORE,
  GET_CLIENTS_AFTER,
  GET_CLIENT_DEALS,
  GET_CLIENT_CASHFLOWS,
  GET_CLIENT_DEALS_ERROR,
  GET_CLIENT_CASHFLOWS_ERROR,
} from '../actionTypes'

interface Adress {
  addressId?: number | null | undefined
  addressType?: string | null | undefined
  country?: string | null | undefined
  street?: string | null | undefined
  town?: string | null | undefined
  zipCode?: string | null | undefined
}

export interface ClientPortfolioType {
  name: string
  portfolioId: number | null
  amount: number | null
  currency: string
  type: string
  amountHistory: []
  assetClassPosition: []
  currencyPosition: []
  instrumentPosition: []
  riskPosition: []
  horizontePosition: []
  externalId: string | null
  accountsBalance: number | null
  riskValue: number | null
  horizonteValue: number | null
}

export interface Client {
  birthDate: Date | string | null
  clientCounterpartyId: string | null
  clientId: number
  portfolios: ClientPortfolioType[]
  contactAddress: Adress | null
  contactAddressEqualsPermanent: boolean
  counterpartyName: string | null
  email: string | null
  firstName: string | null
  identityCardIssuer: string | null
  identityCardNumber: string | null
  identityCardValidity: Date | string | null
  investmentHorizonte: string | null
  investmentQuestionnaireValidTo: Date | null | string
  note: string | null
  permanentAddress: Adress
  personalNumber: string | null
  riskProfile: number | null
  esg: string | null
  state: string | null
  surname: string | null
  telephoneNumber: string | null
  titleAfter: string | null
  titlePre: string | null
  uid: string | null
  nationality: string | null
  roles: string[]
  category: string | null
  skillAndExperience: string | null
  preference: string | null
  lossAbility: string | null
  isID: boolean
  riskValue: number | null
  isRiskValue: boolean
  isRiskValueSec: boolean
  horizonteValue: number | null
  isHorizonteValue: boolean
  isHorizonteValueSec: boolean
  categorySec: string | null
  isCategorySec: boolean
  skillAndExperienceSec: string | null
  isSkillAndExperienceSec: boolean
  preferenceSec: string | null
  isPreferenceSec: boolean
  lossAbilitySec: string | null
  isLossAbilitySec: boolean
  orderComment: string | null
  shouldHaveKcUser: boolean
  shouldResetPassword: boolean
  activeKcUser: boolean
  createdKcUser: boolean
  clientExternalId: string | null
}

export interface MassOrderClient extends Client {
  massOrderByAmount: number | null
  massOrderByQuantity: number | null
  massOrderCurrency: string | null
  coverPercentage: number | null
  exchangedFromTo: boolean
  isCovered: boolean
  selectedPortfolio: ClientPortfolioType
}

export interface ClientRole {
  name: string
  roleId: number
  parentId: number
}

export interface ClientRolesList {
  roles: ClientRole[]
}

interface ClientState {
  clients: Client[]
  client: Client
  clientRoles: ClientRole[]
  clientAccountTotal: Number | null
  loading: boolean,
  clientDeals: ClientDeal[],
  clientCashflows: ClientCashflow[]
}

export interface ClientDeal {
  portfolio: String,
  kid: String,
  tradeDate: Date,
  tradeType: String,
  ticker: String,
  isin: String,
  depositary: String,
  price: number,
  quantity: number,
  fee: number,
  valueDate: Date,
  feeCurrency: String,
  amount: number,
  currency: String,
  firstName: String,
  surname: String,
  order: String,
  idTrade: number,
  tradeTypeDesc: String,
  product: String,
  number: String
}

export interface ClientCashflow {
  portfolio: String,
  kid: String,
  tradeDate: Date,
  paymentId: String,
  text: String,
  amount: number,
  valueDate: Date,
  currency: String,
  firstName: String,
  surname: String,
  number: String,
  sort: String,
  tpid: String
}

export const requiredClientAttributes = [
  'surname',
  'personalNumber',
  'identityCardNumber',
  'street',
  'town',
  'zipCode',
  'country',
  'roles',
]

const INITIAL_STATE = {
  clients: [],
  client: emptyClient,
  clientRoles: [],
  clientAccountTotal: null,
  loading: false,
  clientDeals: [],
  clientCashflows: [],
}

export const clients = (state: ClientState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case GET_CLIENTS_BEFORE:
      return {
        ...state,
        loading: true,
      }
    case GET_CLIENTS_AFTER:
      return {
        ...state,
        loading: false,
      }
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.payload as Client[],
      }
    case GET_CLIENT:
      return {
        ...state,
        client: action.payload as Client,
      }
    case ADD_CLIENT:
      return {
        ...state,
        clients: [...state.clients, action.payload as Client],
      }
    case UPDATE_CLIENT:
      return {
        ...state,
        clients: [...state.clients.filter(c => c.clientId !== action.payload.clientId), action.payload],
        client: action.payload,
      }
    case GET_CLIENT_ROLES:
      return {
        ...state,
        clientRoles: action.payload as ClientRole[],
      }
    case GET_CLIENT_ACCOUNT_TOTAL:
      return {
        ...state,
        clientAccountTotal: action.payload as Number,
      }
    case GET_CLIENT_DEALS:
    case GET_CLIENT_DEALS_ERROR:
        return {
        ...state, 
        clientDeals: action.payload as ClientDeal[],
      }
    case GET_CLIENT_CASHFLOWS:
    case GET_CLIENT_CASHFLOWS_ERROR:
        return {
        ...state,
        clientCashflows: action.payload as ClientCashflow[],
      }
    default:
      return state
  }
}
